import React, { useEffect } from 'react'
import CaseStudyBannerBeurer from '../../container/CaseStudyBanner/CaseStudyBannerBeurer'
import CS_AboutProject from '../../container/CS_about_project/CS_AboutProject'
import CS_WorkprogressList from '../../container/CS_workprogress/CS_WorkprogressList'
import CS_UserPersonal_Beurer from '../../container/CS_UserPer/CS_UserPersonal_Beurer'
import CS_InstantShowcase from '../../container/CS_instant_showcase/CS_InstantShowcase'
import CS_AppMap from '../../container/CS_app_map/CS_AppMap'
import CS_Webpages from '../../container/CS_webpages/CS_Webpages'
import CS_PhoneDesign from '../../container/CS_phone_design/CS_PhoneDesign'
import CS_Support from '../../container/CS_support/CS_Support'
import images from '../../constants/images'
import CS_Result from '../../container/CS_result/CS_Result'
import CS_Tropography_Beurer from '../../container/CS_trophography/CS_Tropography_Beurer'
import CS_ColorPalette_Beurer from '../../container/CS_color_palette/CS_ColorPalette_Beurer'
import CS_DesignSystem_Beurer from '../../container/CS_designSystem/CS_DesignSystem_Beurer'
import CS_Workprogress from '../../container/CS_workprogress/CS_Workprogress'

const BeurerCasestudy = () => {
    useEffect(() => {
        window.scrollTo({top: 0,behavior: "instant"})
       }, [])
  return (
    <div>
        <CaseStudyBannerBeurer/>
        <CS_AboutProject content1="Binary Geckos was commissioned to design and develop an e-commerce platform for Beurer, a leading brand in health and wellness products. The project focused on creating a user-friendly digital marketplace showcasing their medical devices, beauty tools, and fitness equipment." content2="Beurer's existing online presence struggled to effectively showcase their diverse range of health and wellness products to digital-savvy customers. Their website faced challenges in presenting complex product specifications clearly and suffered from poor navigation and filtering capabilities. The limited mobile responsiveness and complicated checkout process led to high cart abandonment rates, while the absence of integrated product reviews left customers without crucial social proof for making informed purchasing decisions." content3="Binary Geckos transformed Beurer's digital presence by developing a user-centric e-commerce platform with a clean, medical-grade design aesthetic. The new platform features intuitive navigation with advanced search capabilities, comprehensive product pages with high-quality imagery, and a streamlined mobile-first design. The addition of product comparison tools, customer reviews, and a simplified checkout process significantly improved the shopping experience, while robust backend integration ensures secure transactions and efficient inventory management."/>
        <CS_Workprogress 
        subh1="Discovery Phase" 
        subc1={"The Discovery Phase begins with stakeholder interviews to understand core business objectives and vision. We analyze competitors to identify market opportunities and potential differentiators."} 

        subh2={"Design Phase"} 
        subc2={"In the Design Phase, we create the information architecture and wireframes to establish core layouts. The UI/UX design process transforms these into engaging visual designs, validated through usability testing."} 
        
        subh3={"Development Phase"} 
        subc3={"Development brings our designs to life with frontend and backend implementation. We integrate e-commerce functionality, payment systems, and ensure smooth performance across all devices."} 
        
        subh4={"Launch Phase"} 
        subc4={"The Launch Phase focuses on quality assurance and performance optimization. We implement staff training and begin collecting user feedback to enable continuous improvement."}/>
        <CS_UserPersonal_Beurer />
        <CS_InstantShowcase imageSrc={images.cs_instant_beurer}/>
        <CS_AppMap imageSrc={images.cs_appmap_beurer}/>
        <CS_DesignSystem_Beurer/>
        <CS_Webpages head1={"Page 1"} 
        content1={"Homepage: A welcoming interface that balances modern design with intuitive navigation."} 
        img1={images.beurer_screen1_img} 
        head2={"Page 2"}
         content2={"Product page: Organized layouts transform technical specifications into an effortless shopping experience."} img2={images.beurer_screen2_img}/>
        <CS_PhoneDesign img={images.cs_phone_beurer}/>
        <CS_Tropography_Beurer/>
        <CS_Support title1={"Binary Geckos collaborated with Beurer to revolutionize"} title2={"health product shopping, creating a seamless"} title3={"digital marketplace for wellness enthusiasts."}/>
        <CS_ColorPalette_Beurer/>
        <CS_Result 
        uxout={"The Beurer website features a clean and intuitive design that is easy to navigate for users with varying technical backgrounds. The website has resulted in:"} 
        uxl1={"Seamless integration of products with detailed specifications."} 
        uxl2={"Enhanced user experience with simplified navigation."} 
        uxl3={"Improved product discovery through advanced search."}
        uxl4={"Comprehensive analytics and reporting system."}
        img={images.Beurer_result_img}
        result_box1={"2+ \u00A0 designers worked on the project"}
        result_box2={"38+ \u00A0 pages were created"}
        result_box3={"60+ \u00A0 hours were invested"}
        result_box4={"3+ \u00A0 weeks were spent"}
        result_box5={"10+ \u00A0 flows were built"}
        />
    </div>
  )
}

export default BeurerCasestudy