import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "./common";
import { HomePage } from "./pages";
import BlogPage from "./pages/BlogPage/BlogPage";
import HomeInsight from "./container/homeinsight/HomeInsight";
import Footer from "./common/footer/Footer";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import Projects from "./pages/Projects/Projects";
// import Sub_blog from "./pages/Sub_blog/Sub_blog";
import Subblog_main_ani from "./pages/Sub_blog/Subblog_main_ani";
import Service_4 from "./pages/service_4/Service_4";
import Service2 from "./pages/Service2Page/Service2";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import DigitalMarketting from "./pages/SubServicePages/DigitalMarketting";
import SocialMediaMgt from "./pages/SubServicePages/SocialMediaMgt";
import PerformanceMarketing from "./pages/SubServicePages/PerformanceMarkrting";
import ContentMarketing from "./pages/SubServicePages/ContentMarketing";
import MarketingAutomation from "./pages/SubServicePages/MarketingAutomation";
import MarketingAnalysis from "./pages/SubServicePages/MarketingAnalysis";
import ServiceTechnology from "./pages/Service2Page/ServiceTechnology";
import DataAnalytic from "./pages/SubServicePages/DataAnalytics";
import WebDevelopment from "./pages/SubServicePages/WebDevelopment";
import MobileappDevelopment from "./pages/SubServicePages/MobileappDevelopment";
import QualityAssuranceTesting from "./pages/SubServicePages/QualityAssuranceTesting";
import SoftwareSolutions from "./pages/SubServicePages/SoftwareSolutions";
import ElevateBranding from "./pages/SubServicePages/ElevateBranding";
import BrandConsulting from "./pages/SubServicePages/BrandConsulting";
import ServiceBranding from "./container/serviceBranding/ServiceBranding";
import ServiceBrandingp from "./pages/Service2Page/ServiceBrandingp";
import LogoDesign from "./pages/SubServicePages/LogoDesign";
import IndustrialDesign from "./pages/SubServicePages/IndustrialDesign";
import GraphicDesign from "./pages/SubServicePages/GraphicDesign";
import Visualization from "./pages/SubServicePages/Visualization";
import UIUXDesign from "./pages/SubServicePages/UIUXDesign";
import D_WebDevelopment from "./pages/SubServicePages/D_WebDevelopment";
import MobileExperience from "./pages/SubServicePages/MobileExperience";
import ServiceDesign from "./pages/Service2Page/ServiceDesign";
import CareerPage from "./pages/CareerPage/CareerPage";
import UsaInternCasestudy from "./pages/CaseStudy/UsaInternCasestudy";
import JobDetails from "./pages/JobDetails/JobDetails";
import Sub_Blog1 from "./pages/Sub_blog/Sub_Blog1";
import Sub_Blog2 from "./pages/Sub_blog/Sub_Blog2";
import Sub_Blog3 from "./pages/Sub_blog/Sub_Blog3";
import { GlobalStateProvider } from "./GlobalStateContext";
import Sub_Blog4 from "./pages/Sub_blog/Sub_Blog4";
import Sub_Blog5 from "./pages/Sub_blog/Sub_Blog5";
import Sub_Blog6 from "./pages/Sub_blog/Sub_Blog6";
import RarerezCasestudy from "./pages/CaseStudy/RarerezCasestudy";
import ComingSoon from "./container/comingsoon/ComingSoon";
import Dustitcasestudy from "./pages/CaseStudy/Dustitcasestudy";
import IdentityCasestudy from "./pages/CaseStudy/IndentityCasestudy";
import BeurerCasestudy from "./pages/CaseStudy/BeurerCasestudy";

const App = () => {
  return (
    <BrowserRouter basename="">
      <GlobalStateProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Insight" element={<BlogPage />} />
          <Route path="/AboutUs" element={<AboutUsPage />} />
          <Route path="/HomeInsight" element={<HomeInsight />} />
          <Route path="/Careers" element={<CareerPage />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Sub_blog" element={<Subblog_main_ani />} />
          <Route path="/Service_4" element={<Service_4 />} />
          <Route path="/Jobdetails" element={<JobDetails />} />

          {/* Dynamic Job Details Route */}
          <Route path="/job/:jobId" element={<JobDetails />} />

          <Route path="/Marketing" element={<Service2 />} />
          <Route path="/ContactUs" element={<ContactUsPage />} />
          <Route path="/DigitalMarketing" element={<DigitalMarketting />} />
          <Route path="/SocialMediaManagement" element={<SocialMediaMgt />} />
          <Route path="/PerformanceMarketing" element={<PerformanceMarketing />} />
          <Route path="/ContentMarketing" element={<ContentMarketing />} />
          <Route path="/MarketingAutomation" element={<MarketingAutomation />} />
          <Route path="/MarketingAnalysis" element={<MarketingAnalysis />} />

          <Route path="/Technology" element={<ServiceTechnology />} />
          <Route path="/DataAnalytics" element={<DataAnalytic />} />
          <Route path="/WebDevelopment" element={<WebDevelopment />} />
          <Route path="/MobileAppDevelopment" element={<MobileappDevelopment />} />
          <Route path="/QualityAssurance&Testing" element={<QualityAssuranceTesting />} />
          <Route path="/SoftwareSolutions" element={<SoftwareSolutions />} />

          <Route path="/Branding" element={<ServiceBrandingp />} />
          <Route path="/ElevateBranding" element={<ElevateBranding />} />
          <Route path="/BrandConsulting" element={<BrandConsulting />} />
          <Route path="/LogoDesign" element={<LogoDesign />} />
          <Route path="/IndustrialDesign" element={<IndustrialDesign />} />
          <Route path="/GraphicDesign" element={<GraphicDesign />} />
          <Route path="/2D-3DVisualization" element={<Visualization />} />

          <Route path="/Design" element={<ServiceDesign />} />
          <Route path="/UIUXDesign" element={<UIUXDesign />} />
          <Route path="/Design-WebDevelopment" element={<D_WebDevelopment />} />
          <Route path="/MobileExperience" element={<MobileExperience />} />

          {/* casestudy */}
          <Route path="/USAInternCasestudy" element={<UsaInternCasestudy />} />
          {/* <Route path="/RarerezCasestudy" element={<RarerezCasestudy />} />
          <Route path="/Dustitcasestudy" element={<Dustitcasestudy />} />
          <Route path="/IdentityProtectcasestudy" element={<IdentityCasestudy />} /> */}
          <Route path="/BeurerCasestudy" element={<BeurerCasestudy />} />
          <Route path="/ComingSoon" element={<ComingSoon />} />
          {/*  */}

          {/* blogs */}
          <Route path="/5-Design-Trends-That-Will-Revolutionise-Your-Mobile-App-in-2024" element={<Sub_Blog1 />} />
          <Route path="/The-ROI-of-a-Strong-Brand-Identity:-Why-Invest-in-Design(The-Marriage-of-Branding-and-Marketing)" element={<Sub_Blog2 />} />
          <Route path="/Data-Driven-Marketing:-Unleashing-Analytics-for-Marketing-Automation-Powerhouse" element={<Sub_Blog3 />} />
          <Route path="/Building-a-Seamless-User-Experience:-The-Unsung-Heroes-QA-&-Testing" element={<Sub_Blog4 />} />
          <Route path="/From-Vision-to-Reality:-Unveiling-the-Software-Development-Proces" element={<Sub_Blog5 />} />
          <Route path="/The-Future-of-Work:-A-Tech-Powered-Transformation-of-the-Business-Landscape" element={<Sub_Blog6 />} />
        </Routes>
        <Footer />
      </GlobalStateProvider>
    </BrowserRouter>
  );
};

export default App;
