import React from 'react'
import "./CS_userpersonal.css"
import images from '../../constants/images'

const CS_UserPersonal_Beurer = ({age,name,occupation,frustrations,goals,needs}) => {
  return (
    <div className='cs_userpersonal_main_wrapp'>
        <div className='cs_userpersonal_base_wrapp'>
            <div className='cs_up_cards_div'>
                <div className='cs_up_card_left'>
                    <h2>User <span>persona</span></h2>
                    <div className='cs_up_left'>
                        <div className='cs_up_left_item'>
                            <div className='up_age'>Age : 35</div>
                            <div className='up_name'>Name : Sarah Mitchell</div>
                            <div className='up_occupation'>Occupation : Marketing Manager </div>
                        </div>
                    
                        <div className='cs_up_black_div'>
                            <div>
                                <h1>Frustrations</h1>
                                <ul>
                                    <li>Limited time for in-store shopping</li>
                                    <li>Difficulty finding reliable product reviews</li>
                                    <li>Complex technical specifications</li>
                                </ul>
                            </div>
                            <div>
                                <h1>Goals</h1>
                                <ul>
                                    <li>Maintain a healthy lifestyle despite busy schedule</li>
                                    <li>Make informed purchasing decisions</li>
                                    <li>Track order history and reorder easily</li>
                                </ul>
                            </div>
                            {/* {needs && ( */}
                            <div>
                                <h1>Needs</h1>
                                <ul>
                                    <li>Easy-to-navigate website</li>
                                    <li>Clear product descriptions</li>
                                    <li>Secure payment process</li>
                                    <li>Mobile shopping capability</li>
                                </ul>
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                </div>
                <div className='cs_up_cards_right'>
                    <img src={images.cs_user_img} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_UserPersonal_Beurer