import React from 'react'
import "./cs_tropography.css"
import images from '../../constants/images'

const CS_Tropography_Beurer = () => {
  return (
    <div className='cs_tropo_main_wrapp_rar'>
        <img src={images.tropography} alt=""  className='cs_tropo_back_img_tropo'/>
        <div className='cs_tropo_base_wrapp'>
            <div className='cs_tropo_title_div'>
                <h1>Typography <span>style</span></h1>
                <div>
                    <p>Regular</p>
                    <p>Medium</p>
                    <p>Semibold</p>
                    <p>Bold</p>
                </div>
            </div>
            <div className='cs_tropo_content_div1'>
                <div >
                </div>
                <div>
                    <h1 className='inter-font'>Inter</h1>
                </div>
            </div>
            <div className='cs_tropo_content_div2'>
                <div className='cs_tropo_inter'>
                    <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p>abcdefghijklmnopqrstuvwxyz</p>
                    <p>0123456789</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_Tropography_Beurer