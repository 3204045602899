import React, { useEffect } from 'react'
import CaseStudyBanner from '../../container/CaseStudyBanner/CaseStudyBanner'
import CS_AboutProject from '../../container/CS_about_project/CS_AboutProject'
import CS_Workprogress from '../../container/CS_workprogress/CS_Workprogress'
import CS_RoadMap from '../../container/CS_roadmap/CS_RoadMap'
import CS_UserPersonal from '../../container/CS_UserPer/CS_UserPersonal'
import CS_InstantShowcase from '../../container/CS_instant_showcase/CS_InstantShowcase'
import CS_AppMap from '../../container/CS_app_map/CS_AppMap'
import CS_DesignSystem from '../../container/CS_designSystem/CS_DesignSystem'
import CS_Webpages from '../../container/CS_webpages/CS_Webpages'
import CS_Tropography from '../../container/CS_trophography/CS_Tropography'
import CS_PhoneDesign from '../../container/CS_phone_design/CS_PhoneDesign'
import CS_Support from '../../container/CS_support/CS_Support'
import CS_ColorPalette from '../../container/CS_color_palette/CS_ColorPalette'
import CS_Result from '../../container/CS_result/CS_Result'
import images from '../../constants/images'

const UsaInternCasestudy = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])
   
  return (
    <div>
        <CaseStudyBanner/>
        <CS_AboutProject content1="Binary Geckos partnered with USAIntern.club, a full-service internship agency, to create a user-friendly and informative website that guides Hungarian students and young professionals through the entire U.S. internship process." content2="USAIntern.club's existing website lacked a clear structure and comprehensive information. It was challenging for potential interns to navigate the site and find the resources they needed." content3="A streamlined user experience that guides visitors through the internship journey—from discovering opportunities and understanding comprehensive information about internship programs, visa requirements, and cultural considerations to securing a J-1 visa—culminating in a clear call to action for personalized guidance from USAIntern.club."/>
        <CS_Workprogress subh1="Discovery & Content Gathering:" subc1={"Binary Geckos collaborated with USAIntern.club to understand their target audience and the information they needed to convey."} subh2={"Information Architecture & Design:"} subc2={"User personas were developed to inform the website's structure and ensure a user-friendly experience for potential interns."} subh3={"Development & Content Creation:"} subc3={"The website was built using a user-centered approach, focusing on clear navigation and engaging content."} subh4={"Launch & Optimization:"} subc4={"Binary Geckos provided ongoing support after the launch, including website analytics monitoring and optimization for search engines."}/>
        {/* <CS_RoadMap/> */}
        <CS_UserPersonal age={"26"} name={"Meet Peter"} occupation={"a recent Hungarian graduate"} 
        frustrations={"Difficulty navigating complex information about U.S. internships and visas."}
        goals={"Find the ideal internship program in the USA."}
        needs={"A comprehensive website with clear information and guidance throughout the application process."}/>
        <CS_InstantShowcase imageSrc={images.cs_instant_showcase}/>
        <CS_AppMap imageSrc={images.cs_circle_mob}/>
        <CS_DesignSystem/>
        <CS_Webpages head1={"Screen 1"} content1={"Developing a resource center with downloadable guides and video tutorials."} img1={images.usa_3rd_img} head2={"Screen 2"} content2={"Integrating interactive tools that help students assess their internship goals and find suitable programs."} img2={images.usa_4th_img}/>
        <CS_PhoneDesign img={images.cs_phone_design}/>
        <CS_Tropography/>
        <CS_Support title1={"Binary Geckos teamed up with USAIntern.club to"} title2={"simplify U.S. internships for Hungarian students,"} title3={"providing key resources and support."}/>
        <CS_ColorPalette/>
        {/* one page is here pending */}
        <CS_Result uxout={"The USAIntern.club website features a clean and intuitive design that is easy to navigate for users with varying technical backgrounds. The website has resulted in:"} uxl1={"Increased website traffic and user engagement."} uxl2={"More qualified leads for USAIntern.club's internship programs."} uxl3={"Enhanced brand image for USAIntern.club as a trusted resource for Hungarian students seeking U.S. internships."}
        img={images.usa_5th_img}
        result_box1={"2+ \u00A0 designers worked on the project"}
        result_box2={"50+ \u00A0 screens were created"}
        result_box3={"65+ \u00A0 hours were invested"}
        result_box4={"2+ \u00A0 weeks were spent"}
        result_box5={"10+ \u00A0 flows were built"}
        />
        
    </div>
  )
}

export default UsaInternCasestudy