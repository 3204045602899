import React from 'react'
import images from '../../constants/images'

const CS_DesignSystem_Beurer = () => {
  return (
    <div>
         <div className='cs_ds_main_wrapp'>
        <div className='cs_ds_base_wrapp'>
            <h1>Design <span>system</span></h1>
            <div className='cs_ds_content_div'>
                <div className='cs_ds_flexrow1_beurer'>
                    <div className='cs_33'>
                        <div className='next_btn_rarerez'>
                            <img src={images.beurer_addtocart} alt=""  className='side_btn_beurer'/>
                        </div>
                    </div>
                    <div className='cs_33'>
                        <div className='next_btn_rarerez'>
                            <img src={images.beurer_register} alt="" className='side_btn_beurer' />
                        </div>
                    </div>
                    
                </div>
                <div className='cs_ds_flexrow1_beurer'>
                    <div className='cs_33'>
                        <div className='next_btn_rarerez'>
                            <img src={images.beurer_search} alt="" className='sigle_btn_beurer'/>
                        </div>
                    </div>
                    <div className='cs_33'>
                        <div className='next_btn_rarerez'>
                        <img src={images.beurer_learnmore} alt="" className='sigle_btn_beurer'/>
                        </div>
                    </div>
                    <div className='cs_33'>
                        <div className='next_btn_rarerez'>
                        <img src={images.beurer_social} alt="" className='sigle_btn_beurer'/>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default CS_DesignSystem_Beurer