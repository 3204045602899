import React from 'react'
import "./casestudybanner.css"
import images from '../../constants/images'

const CaseStudyBannerBeurer = () => {
  return (
    <div className='Case_banner_main_wrapp'>
        <img src={images.casestudy_banner} alt=""  className='case_banner_back_img'/>
        <div className='case_banner_base_wrapp'>
            <img src={images.beurer_logo} alt=""  className='case_banner_logo_img_beurer'/>
            <h1>Beurer</h1>
            <h2>E-commerce Website</h2>
        </div>
    </div>
  )
}

export default CaseStudyBannerBeurer