import React from 'react'

const CS_ColorPalette_Beurer = () => {
  return (
    <div className='cs_color_main_wrapp'>
        <div className='cs_color_base_wrapp'>
            <h1>Color <span>palette</span></h1>
            <div className="cs_color_grid_wrapp_beurer">
                <div className="cs_grid_1_beurer">
                    
                </div>
                <div className="cs_grid_2_beurer">
                    
                </div>
                <div className="cs_grid_3_beurer">
                    
                </div>
                <div className="cs_grid_4_beurer">
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_ColorPalette_Beurer